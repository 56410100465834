// Variables
$links: '&:hover, &:active, &:focus';
$main-color: #22316C;

// Animations
@keyframes pulse {
    from { transform: scale(1); }
    50% { transform: scale(0.85); }
    to { transform: scale(1); }
}

.pulse {
    animation-name: pulse;
    animation-duration: 2s;
    animation-iteration-count: infinite;
}
  
// Components 
.button {
    padding: 10px 30px; 
    border: 1px solid white;
    color: white;
    text-transform: uppercase;
    font-size: 2em;
    background-color: transparent;
    cursor: pointer;
    transition: all .5s;
    display: inline-block;
    &.is-hidden {
        opacity: 0;
        visibility: hidden;
    }

    #{$links} {
        padding: 10px 15px;
    }
}

// Styles
.survey-app {
    height: 100vh;
    display: grid;
    grid-template-rows: 100px 1fr auto; 
    background-color: white;
    position: relative;
    overflow: hidden;
    width: 100%;
    &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        background-color: $main-color;
        display: block;
        transition: all 1s;
    }

    &__init {
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        &.on-top {
            top: 0;
            margin-top: 0;
            transition: .5s top .5s, .3s transform .5s;
            transform: translate(-50%, 0);
        }
    }
    &-logo {
        $logo-width: 300px;
        height: 100px;
        width: $logo-width;
        object-fit: contain;
        object-position: center;
        margin-bottom: 45px;
    }
    &-steps {
        grid-row: -2 / -1;
        padding: 20px 40px;
        background-color: #22316C;
        color: white;
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        justify-content: center;
        font-size: 1.5em;
        opacity: 0;
        .is-init & {
            opacity: 1;
            transition: .5s opacity .2s ;
        }
    }
    &-selector {
        background-color: #fff;
        h1 {
            margin: 0;
            background-color: $main-color;
            color: white;
            text-transform: uppercase;
            padding: 10px;
            text-align: center;
            font-size: 1.5em;
        }
        button {
            width: 100%;
            background-color: white;
            text-transform: uppercase;
            border: 0;
            padding: 15px 30px;
            text-align: left;
            border-bottom: 1px solid #eaeaea;
            font-weight: 600;
            cursor: pointer;
            font-size: 1em;
            position: relative;
            display: flex;
            align-items: center;
            justify-content: space-between;
            &::after {
                font-family: 'FontAwesome';
                content: '\f096';
                font-size: 1.2em;
                color: #666;
            }
            #{$links} {
                background-color: #eee;
                &::after {
                    content: '\f046';
                    color: #229911;
                }
            }
        }
    }
    &-question {
        grid-row: 2 / span 1;
        text-align: center;
        margin: 60px 0;
        position: relative;
        transition: all 1s;
        visibility: hidden;
        opacity: 0;
        .is-init & {
            opacity: 1;
            visibility: visible;
            transition: 1s opacity .5s; 
        }
        h2 {
            text-transform: uppercase;
            color: $main-color;
            margin: 0;
            margin-bottom: 60px;
        }

        .question {
            &.fade {
                &-exit {
                    opacity: 1;
                    &.fade-exit-active {
                        position: absolute;
                        top: 0;
                        left: 0;
                        width: 100%;
                        opacity: 0;
                        transition: opacity .3s linear;
                    }
                    &-done {
                        opacity: 0;
                    }
                }
                &-enter {
                    opacity: 0;
                    &.fade-enter-active {
                        opacity: 1;
                        transition: opacity .3s linear .3s;
                    }
                }
                &-enter-done {
                    opacity: 1;
                }
            }
        }
    }

    &-answer {
        // padding: 60px 0;
        .answer {
            display: flex;
            align-items: center;
            justify-content: center;
        }
        .with-icons {
            img {
                width: 80px;
                display: block;
                margin: 0 auto;
                margin-bottom: 30px;
                opacity: 0;
                transform: scale(.95);
                transition: all .3s;
                #{$links} {
                    transition: .3s transform 0s;
                    transform: scale(1.1) !important;
                }
            }
            span {
                opacity: 0;
                display: inline-block;
                text-transform: uppercase;
                color: $main-color;
                font-weight: 600;
                transition: .5s opacity .5s;
            }

            .icon {
                .fade-enter-active & {
                    @for $i from 1 to 5 {
                        &:nth-child(#{$i}) {
                            img {
                                // transition-delay: $i * .55s;
                                transition: .5s transform $i * .3s, .5s opacity $i * .3s;
                            }
                            span {
                                transition-delay: $i * .3s;
                            }
                        }
                    }
                    img {
                        opacity: 1;
                        transform: scale(1);
                    }
                    span {
                        opacity: 1;
                    }
                }
                .fade-enter-done & {
                    img {
                        opacity: 1;
                        transform: scale(1);
                    }
                    span {
                        opacity: 1;
                    }
                }
            }

        }
        
        .satisfaction {
            &-option {
                cursor: pointer;
                text-align: center;
                margin: 0 30px;
            }
        }
        .yesno {
            width: 600px;
            margin: 0 auto;
            justify-content: space-evenly;
            align-items: center;
            &-option {
                cursor: pointer;
            }
        }

        .input-text {
            width: 600px;
            margin: 0 auto;
            &-field {
                width: 100%;
                height: 150px;
                border: 1px solid $main-color;
                outline: none;
                margin-bottom: 20px;
                font-size: 1.2em;
                padding: 10px;
                opacity: 0;
                transition: all .3s;
            }
            .form-button {
                display: inline-block;
                padding: 15px 20px;
                background-color: $main-color;
                color: white;
                text-transform: uppercase;
                font-size: 1.2em;
                border: 0;
                cursor: pointer;
                opacity: 0;
                transition: all .3s;
            }

            .fade-enter-active &, .fade-enter-done & {
                .input-text-field, .form-button {
                    opacity: 1;
                }       
            }

        }
    }

    &-greetings {
        position: absolute;
        color: white;
        display: flex;
        width: 100%;
        height: 100vh;
        align-items: center;
        justify-content: center;
        text-align: center;
        text-transform: uppercase;
        opacity: 0;
        visibility: hidden;
        pointer-events: none;
        transition: all .3s;
        .is-greeting & {
            opacity: 1;
            visibility: visible;
            background-color: $main-color;
        }
        h1, h2 {
            line-height: 1;
            margin: 0;
        }
        h1 {
            font-size: 6em;
            margin-bottom: 15px;
        }
        h2 {
            font-size: 1.25em;
        }
    }

    &.is-init {
        &::before {
            top: 100%;
        }
    }
}
